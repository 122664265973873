import { 
  PublicLayout
} from "./containers/layouts";

import { Route } from "react-router-dom";

import {
  HomePage,
  Campaigns,
  CampaignsDetails,
  AllCampaigns,
  News,
  NewsDetails,
  MemberWorkplaces,
  MemberWorkplacesDetails,
  FAQ,
  TuzlaKartInfo,
  GencKartInfo,
} from "./containers/pages";
import MyAccount from "./containers/pages/PublicLayout/MyAccount/MyAccount";

const PUBLIC_BASE_URL = "/";

const routes = {
  PublicLayout: {
    component: PublicLayout,
    children: [
      {
        path: PUBLIC_BASE_URL,
        exact: true,
        component: HomePage,
        routeComponent: Route,
      },
      {
        path: `${PUBLIC_BASE_URL}hesabim`,
        exact: true,
        component: MyAccount,
        routeComponent: Route,
      },
      {
        path: `${PUBLIC_BASE_URL}kampanyalar`,
        exact: true,
        component: Campaigns,
        routeComponent: Route,
      },
      {
        path: `${PUBLIC_BASE_URL}kampanyalar/all`,
        exact: false,
        component: AllCampaigns,
        routeComponent: Route,
      },
      {
        path: `${PUBLIC_BASE_URL}kampanyalar/:id`,
        exact: false,
        component: CampaignsDetails,
        routeComponent: Route,
      },
      {
        path: `${PUBLIC_BASE_URL}haberler`,
        exact: true,
        component: News,
        routeComponent: Route,
      },
      {
        path: `${PUBLIC_BASE_URL}haberler/:id`,
        exact: false,
        component: NewsDetails,
        routeComponent: Route,
      },
      {
        path: `${PUBLIC_BASE_URL}uye-firmalar`,
        exact: true,
        component: MemberWorkplaces,
        routeComponent: Route,
      },
      {
        path: `${PUBLIC_BASE_URL}uye-firmalar/:id`,
        exact: false,
        component: MemberWorkplacesDetails,
        routeComponent: Route,
      },
      {
        path: `${PUBLIC_BASE_URL}faq`,
        exact: true,
        component: FAQ,
        routeComponent: Route,
      },
      {
        path: `${PUBLIC_BASE_URL}tuzla-kart-nedir`,
        exact: true,
        component: TuzlaKartInfo,
        routeComponent: Route,
      },
      {
        path: `${PUBLIC_BASE_URL}genc-kart-nedir`,
        exact: true,
        component: GencKartInfo,
        routeComponent: Route,
      }
    ],
  }
};

export default routes;

